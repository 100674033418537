import React, { useContext } from "react";
import { AppStateContext, BanjosInput } from "../../../shared";

export const EditCategoryLink = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { index, storeTemplate } = appState;
  const onLinkChange = (e) => {
    const { name, value } = e.target;
    appScopeDispatch({
      type: "ON_CATEGORY_LINK_CHANGE",
      payload: {
        value,
      },
    });
  };
  return (
    <>
      <BanjosInput
        type="text"
        description={`https://www.banjos.com.au/${storeTemplate.categories[index].slug}?`}
        label={storeTemplate.categories[index].name}
        placeholder="UTM code"
        onChange={onLinkChange}
        value={storeTemplate.categories[index].link ?? ""}
      />
    </>
  );
};
