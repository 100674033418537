import { useEffect } from "react";
import { toast } from "react-toastify";
import uniqid from "uniqid";
import { useImmer } from "use-immer";
import { API } from "../../../shared";

const initialData = {
  items: [
    {
      id: "1",
      content: "Queensland",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "2",
      content: "Tasmania",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "3",
      content: "New South Wales",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "5",
      content: "South Australia",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "6",
      content: " Victoria",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "7",
      content: "Already have a card?",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "8",
      content: "Events",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "9",
      content: "Contact us",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "10",
      content: "Help",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "11",
      content: "About",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "12",
      content: "Career",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "13",
      content: "Franchising",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "14",
      content: "Privacy Policy",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "15",
      content: "Terms & Conditions",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "16",
      content: "The Daniel Morcombe Foundation",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
    {
      id: "17",
      content: "Carrie's Beanies 4 Brain Cancer",
      link: "https://banjos-nxt-dev.web.app/",
      show: true,
      isExternal: false,
    },
  ],

  columns: [
    {
      id: "column-1",
      title: "Bakery Locations",
      show: true,
      itemIds: ["1", "2", "3", "5", "6"],
    },
    {
      id: "column-2",
      title: "Quick Links",
      show: true,
      itemIds: ["7", "8", "9", "10"],
    },
    {
      id: "column-3",
      title: "About Us",
      show: true,
      itemIds: ["11", "12", "13"],
    },
    {
      id: "column-4",
      title: "Learn More",
      show: true,
      itemIds: ["14", "15"],
    },
    {
      id: "column-5",
      title: "Fundraising",
      show: true,
      itemIds: ["16", "17"],
    },
  ],
};

export const useFooterTemplate = () => {
  const [state, setState] = useImmer(initialData);
  const [footer, setFooter] = useImmer({
    isBusy: false,
    saveButtonBusy: false,
    showCancelButton: false,
  });

  const getFooterDetails = () => {
    setFooter((draft) => {
      draft.isBusy = true;
    });
    API.get("footer")
      .then((res) => {
        if (res.data.success) {
          setState((draft) => {
            draft.items = res.data.data.items;
            draft.columns = res.data.data.columns;
          });
          setFooter((draft) => {
            draft.isBusy = false;
            draft.showCancelButton = false;
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setFooter((draft) => {
          draft.isBusy = false;
        });
      });
  };

  useEffect(() => {
    getFooterDetails();
  }, []);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(state.columns);
      const draggedColumn = state.columns.find(
        (item) => item.id === draggableId
      );
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggedColumn);

      setState((draft) => {
        draft.columns = newColumnOrder;
      });
      return;
    }

    const start = state.columns.find((item) => item.id === source.droppableId);
    const finish = state.columns.find(
      (item) => item.id === destination.droppableId
    );

    if (start === finish) {
      const newItemIds = Array.from(start.itemIds);
      newItemIds.splice(source.index, 1);
      newItemIds.splice(destination.index, 0, draggableId);

      const updatedColumns = state.columns.map((column) => {
        if (column.id === start.id) {
          return { ...column, itemIds: newItemIds };
        } else {
          return column;
        }
      });

      setState((draft) => {
        draft.columns = updatedColumns;
      });

      return;
    }

    // Moving from one list to another
    const startItemIds = Array.from(start.itemIds);
    startItemIds.splice(source.index, 1);

    const finishItemIds = Array.from(finish.itemIds);
    finishItemIds.splice(destination.index, 0, draggableId);

    const updatedColumns = state.columns.map((column) => {
      if (column.id === start.id) {
        return { ...column, itemIds: startItemIds };
      } else if (column.id === finish.id) {
        return { ...column, itemIds: finishItemIds };
      } else {
        return column;
      }
    });

    setState((draft) => {
      draft.columns = updatedColumns;
    });
  };
  const onAddItem = (columnIndex) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    const newItem = {
      id: `${uniqid()}`,
      content: "new item",
      link: "",
      show: true,
    };
    setState((draft) => {
      draft.items = [...draft.items, newItem];
      draft.columns[columnIndex].itemIds = [
        ...draft.columns[columnIndex].itemIds,
        newItem.id,
      ];
    });
  };

  const onDeleteItem = (columnIndex, itemId) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    setState((draft) => {
      draft.items = draft.items.filter((item) => item.id !== itemId);
      draft.columns[columnIndex].itemIds = draft.columns[
        columnIndex
      ].itemIds.filter((item) => item !== itemId);
    });
  };

  const onAddColumn = () => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    setState((draft) => {
      draft.columns = [
        ...draft.columns,
        {
          id: `${uniqid()}`,
          title: "New Column",
          itemIds: [],
          show: true,
        },
      ];
    });
  };
  const onDeleteColumn = (id) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    setState((draft) => {
      draft.columns = draft.columns.filter((item) => item.id !== id);
    });
  };

  const onChangeItem = (itemId, e) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    const { value, name } = e.target;
    const updatedItems = state.items.map((item) => {
      if (item.id === itemId) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setState((draft) => {
      draft.items = updatedItems;
    });
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
  };

  const onContentChange = (type, columnIndex, value) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    if (type === "column") {
      setState((draft) => {
        draft.columns[columnIndex].title = value;
      });
    }
  };

  const onHide = (type, itemId, columnIndex) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    if (type === "column") {
      setState((draft) => {
        draft.columns[columnIndex].show = !draft.columns[columnIndex].show;
      });
    } else if (type === "item") {
      const updatedItems = state.items.map((item) => {
        if (item.id === itemId) {
          return { ...item, show: !item.show };
        } else {
          return item;
        }
      });
      setState((draft) => {
        draft.items = updatedItems;
      });
    } else {
      return;
    }
  };

  const onUpdateFooter = () => {
    setFooter((draft) => {
      draft.saveButtonBusy = true;
    });
    API.put("footer", state)
      .then((res) => {
        if (res.data.success) {
          toast.success("Footer Updated successfully");
          setFooter((draft) => {
            draft.saveButtonBusy = false;
            draft.showCancelButton = false;
          });
        }
      })
      .catch((err) => {
        console.log("errr", err.response);
        setFooter((draft) => {
          draft.saveButtonBusy = false;
        });
        let errorText = "";
        Object.keys(err.response.data.errors).forEach((key) => {
          errorText = errorText + err.response.data.errors[key];
        });
        toast.error(errorText);
      });
  };

  const onExternalChange = (e, id) => {
    setFooter((draft) => {
      draft.showCancelButton = true;
    });
    const updatedItems = state.items.map((item) => {
      if (item.id === id) {
        return { ...item, isExternal: !item.isExternal };
      } else {
        return item;
      }
    });
    setState((draft) => {
      draft.items = updatedItems;
    });
  };

  return {
    state,
    onDragEnd,
    onAddItem,
    onDeleteItem,
    onAddColumn,
    onDeleteColumn,
    onChangeItem,
    onContentChange,
    onHide,
    footer,
    onUpdateFooter,
    onExternalChange,
    getFooterDetails,
  };
};
