import React, { useContext } from "react";
import { AppStateContext, BanjosInput } from "../../../shared";

export const EditText = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { selectedTemplate, selectedElement } = appState;
  const fontDetails = {
    fontName: "Grenette Pro",
    fontWeight: "Regular",
    fontSize: "16",
  };

  const onChangeColor = (e) => {
    appScopeDispatch({
      type: "ONCHANGE_COLOR",
      payload: {
        name: selectedElement,
        value: e.target.value,
      },
    });
  };

  return (
    <>
      <BanjosInput
        type="color"
        label={appState[selectedTemplate][selectedElement].componentName}
        fontDetails={fontDetails}
        value={appState[selectedTemplate][selectedElement].color}
        onChange={onChangeColor}
      />
    </>
  );
};
