import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CkEditorPreview, StorePagePreview } from "./containers";

export const Preview = () => {
  const params = useParams();
  const { type } = params;
  return (
    <>
      {" "}
      {type === "storeTemplate" ? (
        <StorePagePreview />
      ) : "custom" ? (
        <CkEditorPreview />
      ) : null}
    </>
  );
};
