import { useContext } from "react";
import ContentEditable from "react-contenteditable";
import { useImmer } from "use-immer";
import { AppStateContext, onElementClick } from "../../../shared";

export function FavouriteCategoryBlock() {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { favCategories } = appState.storeTemplate;
  const { selectedElementType } = appState;
  const [state, setState] = useImmer({
    btnDisabled: false,
    selectedSection: null,
  });

  const onAddToFavourite = () => {
    setState((draft) => {
      draft.btnDisabled = true;
    });
    appScopeDispatch({
      type: "ON_ADD_FAV",
    });
    setTimeout(() => {
      setState((draft) => {
        draft.btnDisabled = false;
      });
    }, 2000);
  };
  const onDeleteItem = (i) => {
    appScopeDispatch({
      type: "ON_DELETE_FAV",
      payload: {
        index: i,
      },
    });
  };
  const onClickText = (ele, i, e) => {
    e.stopPropagation();
    appScopeDispatch({
      type: "ON_FAV_TEXT_CLICK",
      payload: {
        index: i,
        selectedElement: ele,
        selectedElementType: "favourite-section",
      },
    });
  };

  const onChangeText = (name, value, i) => {
    appScopeDispatch({
      type: "ON_FAV_TEXT_CHANGE",
      payload: {
        index: i,
        name,
        value,
      },
    });
  };
  return (
    <div className="favourite-category-block-wrapper">
      <div
        className="favourite-category-block-container"
        onClick={(e) => {
          onElementClick(
            appScopeDispatch,
            "favCategories",
            "favourite-section"
          );
        }}
      >
        <div className="favourite-category-block-head-wrap">
          <div className="favourite-category-block-head">
            Banjo's Favourites
          </div>
          <button
            className="secondary-btn favourite-category-add-item"
            onClick={() => onAddToFavourite()}
            disabled={state.btnDisabled}
          >
            <img src="/icons/add_icon.svg" />
            ADD
          </button>
        </div>
        <div className="favourite-category-block-items container container-max">
          {favCategories.map((data, index) => (
            <div
              key={index}
              className={`favourite-category-block-item ${
                selectedElementType === "favourite-section" &&
                state.selectedSection === index
                  ? "element-selected"
                  : ""
              }`}
              onClick={() => {
                setState((draft) => {
                  draft.selectedSection = index;
                });
              }}
            >
              <div className="first-col">
                <ContentEditable
                  className="category-block-title"
                  style={{
                    color: `${data.titleColor}`,
                  }}
                  onClick={(e) => {
                    setState((draft) => {
                      draft.selectedSection = index;
                    });
                    onClickText("title", index, e);
                  }}
                  html={data.title} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  onChange={(e) => onChangeText("title", e.target.value, index)} // handle innerHTML change
                  tagName="div"
                />
                {/* <div
                  className="category-block-title"
                  style={{
                    color: `${data.titleColor}`,
                  }}
                >
                  {data.title}
                </div> */}
                <ContentEditable
                  className="category-block-des"
                  style={{
                    color: `${data.desColor}`,
                  }}
                  onClick={(e) => {
                    setState((draft) => {
                      draft.selectedSection = index;
                    });
                    onClickText("des1", index, e);
                  }}
                  html={data.des1} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  onChange={(e) => onChangeText("des1", e.target.value, index)} // handle innerHTML change
                  tagName="p"
                />
                <ContentEditable
                  className="category-block-des"
                  style={{
                    color: `${data.desColor}`,
                  }}
                  onClick={(e) => {
                    setState((draft) => {
                      draft.selectedSection = index;
                    });
                    onClickText("des2", index, e);
                  }}
                  html={data.des2} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  onChange={(e) => onChangeText("des2", e.target.value, index)} // handle innerHTML change
                  tagName="p"
                />
                {/* <div
                  className="category-block-des"
                  style={{
                    color: `${data.desColor}`,
                  }}
                >
                  <p>{data.des1}</p>

                  <p>{data.des2}</p>
                </div> */}
              </div>
              <div className="secound-col">
                <button
                  className="category-block-btn"
                  style={{
                    color: `${data.btnColor}`,
                    backgroundColor: `${data.btnBgColor}`,
                  }}
                >
                  <ContentEditable
                    onClick={(e) => {
                      setState((draft) => {
                        draft.selectedSection = index;
                      });
                      onClickText("btnText", index, e);
                    }}
                    html={data.btnText} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onChange={(e) =>
                      onChangeText("btnText", e.target.value, index)
                    } // handle innerHTML change
                    tagName="div"
                  />
                </button>
                <button
                  className="secondary-btn delete-btn"
                  onClick={() => onDeleteItem(index)}
                >
                  <img src="/icons/delete_icon.svg" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
