import { useSearchParams } from "react-router-dom";

export function TemplateFooter(props) {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  return (
    <footer className="Footer-wrapper">
      <div className="Footer-container">
        <div className="footer-big-wrap">
          <div
            className={`footer-big container-max container ${
              mode === "mobile" ? "mobile" : ""
            }`}
          >
            {/* <div className="footer-big-row"> */}
            <div className="logo-div">
              <img src="/images/banjos-footer-logo.svg" className="logo" />

              <div className="Download-div">
                <p className="download">Download App</p>
                <div className="app-store-logos">
                  <a
                    href="https://apps.apple.com/us/app/banjos-ordering/id1519152955"
                    target="_blank"
                  >
                    <img src="/images/app-store.svg" className="icon" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=au.com.banjos.orderingapp"
                    target="_blank"
                  >
                    <img src="/images/play-store.svg" className="icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="arrangement col-md-8">
              <div className="item">
                <h4>Bakery Locationss</h4>
                <p>
                  <a href="/stores/Queensland">Queensland</a>
                  <a href="/stores/Tasmania">Tasmania</a>
                  <a href="/stores/NewSouthWales">New South Wales</a>
                  <a href="/stores/SouthAustralia">South Australia</a>
                  <a href="/stores/Victoria">Victoria</a>
                </p>
              </div>
              <div className="item">
                <h4>Quick Links</h4>
                <p>
                  <a href="/#">Already have a card?</a>
                  <a href="/#">Events</a>
                  <a href="/#">Contact us</a>
                  <a href="/#">Help</a>
                </p>
              </div>
              <div className="item">
                <h4>About Us</h4>
                <p>
                  <a href="/privacy-policy">About</a>
                  <a href="/terms-and-conditions">Careers</a>
                  <a href="/terms-and-conditions">Franchising</a>
                </p>
              </div>
              <div className="item">
                <h4>Learn More</h4>
                <p>
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/terms-and-conditions">Terms & Conditions</a>
                </p>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div className="footer-small-wrap">
          <div
            className={`footer-small container-max container ${
              mode === "mobile" ? "mobile" : ""
            }`}
          >
            <div className="social">
              <a href="https://www.facebook.com/banjosbakery/" target="_blank">
                <img src="/icons/facebook.svg" />
              </a>
              <a href="https://www.instagram.com/banjosbakery/" target="_blank">
                <img src="/icons/instagram.svg" />
              </a>
              <a href="https://twitter.com/BanjosBakery" target="_blank">
                <img src="/icons/twitter.svg" />
              </a>
            </div>
            <div className="copyright">
              <p>All Rights Reserved. Copyright © Banjo’s Pty Ltd.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
