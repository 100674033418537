import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const ModalContainer = ({
  isOpen,
  toggleModal,
  body,
  footer,
  header,
  centered,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered={centered}>
      <ModalHeader toggle={toggleModal}>{header}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};
