import React from "react";

export const Unauthorized = () => {
  return (
    <div className="un-auth">
      <div className="content">
        <img src="/images/new-logo.png" />
        <h3>401 Unauthorized</h3>
        <p>You don't have the permission to view this page.</p>
      </div>
    </div>
  );
};
