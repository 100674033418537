import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Item } from "./Item";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";

export const Column = (props) => {
  const { column, items, index, onContentChange, onChangeItem } = props;

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided) => (
        <div
          className={`column-container ${!column.show && "hide"} `}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="column-header">
            <img {...provided.dragHandleProps} src="/icons/drag_handle.png" />
            <div className={`colum-content`}>
              <ContentEditable
                html={column.title}
                className={` ${!column.show && "hide"}`}
                disabled={false}
                onChange={(e) => {
                  onContentChange("column", index, e.target.value);
                }}
                tagName="h4"
              />
            </div>
          </div>

          <Droppable droppableId={column.id} type="task">
            {(provided, snapshot) => (
              <>
                <div
                  className="item-list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {items.map((item, i) => (
                    <Item
                      column={column}
                      key={item.id}
                      item={item}
                      index={i}
                      onChangeItem={onChangeItem}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              </>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};
