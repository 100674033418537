import React, { useContext } from "react";
import {
  AppStateContext,
  BanjosInput,
  Dropzone,
  onShowHideLogoElement,
} from "../../../shared";

export const EditSupporters = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { index, storeTemplate } = appState;
  const { proudlySupp } = storeTemplate;
  const onUpload = (url) => {
    appScopeDispatch({
      type: "ON_SUPPOERTERS_CHANGE",
      payload: {
        name: "logo",
        value: url,
      },
    });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    appScopeDispatch({
      type: "ON_SUPPOERTERS_CHANGE",
      payload: {
        name,
        value,
      },
    });
  };
  return (
    <>
      {proudlySupp[index] && (
        <>
          <BanjosInput
            type="text"
            name="link"
            label={`Image ${index + 1}`}
            value={proudlySupp[index].link ?? ""}
            onChange={(e) => onChange(e, index)}
            placeholder="Link"
            isHideElement={true}
            isHide={proudlySupp[index].show}
            onClickHideElement={() =>
              onShowHideLogoElement(appScopeDispatch, "proudlySupp", index)
            }
          />
          <Dropzone onUpload={onUpload} />
        </>
      )}
    </>
  );
};
