import React, { useContext } from "react";
import { AppStateContext, BanjosInput } from "../../../shared";
import {
  UncontrolledAccordion,
  AccordionHeader,
  AccordionBody,
  AccordionItem,
} from "reactstrap";
import parse from "html-react-parser";
export const EditFavouritesSection = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { favCategories } = appState.storeTemplate;
  const fontDetails = {
    fontName: "Grenette Pro",
    fontWeight: "Regular",
    fontSize: "16",
  };
  const onChange = (e, index) => {
    const { name, value } = e.target;
    appScopeDispatch({
      type: "ONCHANGE_FAV",
      payload: {
        index,
        name,
        value,
      },
    });
  };

  return (
    <>
      <UncontrolledAccordion flush className="banjos-accordian">
        {favCategories.map((item, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={`${index}`}>
              {parse(favCategories[index].title ?? "")}
            </AccordionHeader>
            <AccordionBody accordionId={`${index}`}>
              <UncontrolledAccordion flush className="banjos-accordian">
                <AccordionItem>
                  <AccordionHeader targetId="2" className="sub-head">
                    Heading
                  </AccordionHeader>
                  <AccordionBody accordionId="2" className="pt-3">
                    <BanjosInput
                      type="color"
                      value={favCategories[index].titleColor}
                      fontDetails={fontDetails}
                      name="titleColor"
                      onChange={(e) => onChange(e, index)}
                    />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3" className="sub-head">
                    Details
                  </AccordionHeader>
                  <AccordionBody accordionId="3" className="pt-3">
                    <BanjosInput
                      type="color"
                      name="desColor"
                      value={favCategories[index].desColor}
                      fontDetails={fontDetails}
                      onChange={(e) => onChange(e, index)}
                    />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="4" className="sub-head">
                    Button
                  </AccordionHeader>
                  <AccordionBody accordionId="4">
                    <div className="favourite-section-button-editor">
                      <BanjosInput
                        type="color"
                        name="btnBgColor"
                        value={favCategories[index].btnBgColor}
                        onChange={(e) => onChange(e, index)}
                      />
                      <BanjosInput
                        type="text"
                        name="link"
                        value={favCategories[index].link}
                        onChange={(e) => onChange(e, index)}
                        placeholder="Link"
                      />
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </>
  );
};
