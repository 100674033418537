import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Column } from "./Column";

export const FooterTemplate = ({
  state,
  onDragEnd,
  onAddItem,
  onContentChange,
  onChangeItem,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <footer className="Footer-wrapper">
        <div className="Footer-container">
          <div className="footer-big-wrap">
            <div className="footer-big container-max container">
              <Droppable
                droppableId="all-columns"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="edit-arrangement"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columns.map((column, index) => {
                      const items = column?.itemIds?.map((id) =>
                        state.items.find((item) => item.id === id)
                      );
                      return (
                        <Column
                          key={column.id}
                          column={column}
                          items={items}
                          index={index}
                          onAddItem={onAddItem}
                          onContentChange={onContentChange}
                          onChangeItem={onChangeItem}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </footer>
    </DragDropContext>
  );
};
