import React from "react";
import { useBanjosNavBar } from "../hooks";
import { CustomDropdown } from "./CustomDropdown";

export const BanjosNavbar = () => {
  const {
    onPublish,
    state,
    navigate,
    onUpdate,
    updateMode,
    location,
    selectedTemplate,
    onDropdownClick,
    handleDropDownChange,
    dropdownRef,
  } = useBanjosNavBar();
  const { isBusy, isOpen, value } = state;

  return (
    <div className="banjos-navbar-wrapper">
      <div className="Navbar-content">
        <div className="left-section">
          <img
            className="text-logo"
            alt="logo"
            src="/images/BanjosTextLogo.svg"
          />
        </div>
        <div className="right-section">
          {location.pathname === "/" ||
          location.pathname.startsWith("/preview") ? (
            <div className="user-sign-in">
              <img className="user-logo" alt="logo" src="/icons/ic_user.svg" />
              <span className="signin-text">Admin</span>
            </div>
          ) : (
            <>
              {location.pathname.includes("custom") && (
                <CustomDropdown
                  onDropdownClick={onDropdownClick}
                  handleDropDownChange={handleDropDownChange}
                  value={value}
                  isOpen={isOpen}
                  dropdownRef={dropdownRef}
                />
              )}
              <a href={`/preview/${selectedTemplate}`} target="_blank">
                <img src="/icons/run_preview.svg" className="preview-btn" />
              </a>
              <button
                disabled={isBusy}
                onClick={() => {
                  updateMode ? onUpdate("draft") : onPublish("draft");
                }}
                className="primary-btn"
              >
                save changes
              </button>
              <button
                disabled={isBusy}
                onClick={() =>
                  updateMode ? onUpdate("publish") : onPublish("publish")
                }
                className="primary-btn"
              >
                publish now
              </button>
              <button onClick={() => navigate("/")} className="secondary-btn">
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
