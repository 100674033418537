import React from "react";
import webIcon from "../../assets/images/web-icon.svg";
import mobileIcon from "../../assets/images/mobile-icon.svg";
import arrowIcon from "../../assets/images/down-arrow.svg";

export const CustomDropdown = ({
  onDropdownClick,
  handleDropDownChange,
  value,
  isOpen,
  dropdownRef,
}) => {
  return (
    <div
      ref={dropdownRef}
      className="responsiveDropdown"
      onClick={onDropdownClick}
    >
      <button>
        <img src={value === "desktop" ? webIcon : mobileIcon} />
        <img src={arrowIcon} />
      </button>
      <ul className={isOpen && "open"}>
        <li
          onClick={(e) => {
            e.stopPropagation();
            handleDropDownChange("desktop");
          }}
          className={value === "desktop" && "selected"}
        >
          <img src={webIcon} />
          <span>Desktop</span>
        </li>
        <li
          onClick={(e) => {
            e.stopPropagation();
            handleDropDownChange("mobile");
          }}
          className={value === "mobile" && "selected"}
        >
          <img src={mobileIcon} />
          <span>Mobile</span>
        </li>
      </ul>
    </div>
  );
};
