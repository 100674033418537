import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";

export const Item = (props) => {
  const { item, index, onChangeItem, column } = props;
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`item-container ${snapshot.isDragging && "dragging"}`}
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <img {...provided.dragHandleProps} src="/icons/drag_handle.png" />
          <div className={`content`}>
            <ContentEditable
              html={item.content}
              className={`${(!item.show || !column.show) && "hide"}`}
              disabled={false}
              onChange={(e) => {
                onChangeItem(item.id, {
                  target: {
                    value: e.target.value,
                    name: "content",
                  },
                });
              }}
              tagName="div"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};
