import React, { createContext } from "react";
import { useAppScope } from "../hooks";

export const AppStateContext = createContext({});

// context provider
export const AppScopeProvider = ({ children }) => {
  const value = useAppScope();

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
