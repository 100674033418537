import styled from "styled-components";
import parse from "html-react-parser";

export function ImageBannerBlock(props) {
  const { textElement, imageElement } = props;
  const Text = styled.span`
    color: ${textElement.color};
  `;
  return (
    <div className="image-banner-block-wrapper">
      <div className="image-banner-block-container">
        <img
          src={imageElement?.url}
          alt={imageElement?.alt}
          title={imageElement?.imageDescription}
          className="banner-image"
        />
        <Text className="banner-fancy-text">
          {parse(textElement?.text ?? "")}
        </Text>
      </div>
    </div>
  );
}
