import React from "react";
import { StoreTemplate } from "./containers";
import CkEditorTemplate from "./containers/CkEditorTemplate";

export const EditorTemplates = ({ type }) => {
  return (
    <div>
      {type === "storeTemplate" ? (
        <StoreTemplate />
      ) : "custom" ? (
        <CkEditorTemplate />
      ) : null}
    </div>
  );
};
