import React, { useEffect } from "react";
import { Row } from "reactstrap";
import { EditorCanvas, EditorToolbar } from "./containers";

export const UiEditor = () => {
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  return (
    <div className="template-editor-wrapper">
      <Row className="gx-0">
        <div className="template-editor-canvas">
          <EditorCanvas />
        </div>
        <div className="template-editor-tools">
          <EditorToolbar />
        </div>
      </Row>
    </div>
  );
};
