import {
  appScopeReducer,
  getStoreList,
  initialData,
} from "../actions/appScopeActions";
import { useImmerReducer } from "use-immer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useAppScope = () => {
  const { pathname } = useLocation();

  const [appState, appScopeDispatch] = useImmerReducer(
    appScopeReducer,
    initialData
  );

  useEffect(() => {
    if (appState && !pathname.includes("preview")) {
      localStorage.setItem("admin-data", JSON.stringify(appState));
    }
  }, [appState, pathname]);

  useEffect(() => {
    const token = localStorage.getItem("admin-token");
    if (token) {
      getStoreList(appScopeDispatch);
    }
  }, []);

  return { appState, appScopeDispatch };
};
