import parse from "html-react-parser";

export function TextBlock({ data }) {
  const { descriptionBox1, descriptionBox2, doodleImage } = data;
  return (
    <div className="text-block-wrapper">
      <div
        className="text-block-container"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,.39), rgba(255,255,255,.39)),url(${doodleImage.url})`,
        }}
      >
        <div className="text-items-container container container-max">
          <div className="text-box-item">
            <div className="text-box-item-content">
              {parse(descriptionBox1)}
            </div>
          </div>
          <div className="text-box-item">
            <div className="text-box-item-content">
              {parse(descriptionBox2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
