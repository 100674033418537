import React, { useContext } from "react";
import {
  TemplateNavbar,
  TemplateFooter,
  API,
  AppStateContext,
} from "../../../shared";
import { Editor as BalloonEditor } from "ckeditor5-custom-build/build/ckeditor";
import { useSearchParams } from "react-router-dom";
import { CkEditorDesktop, CkEditorMobile } from "../components";

export default function CkEditorTemplate() {
  const [queryParameters] = useSearchParams();
  const isMobile = queryParameters.get("mode") === "mobile";
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { custom, mobile_body } = appState;

  const onChange = (data, type) => {
    appScopeDispatch({
      type: type === "mobile" ? "ON_CUSTOM_MOBILE_CHANGE" : "ON_CUSTOM_CHANGE",
      payload: {
        data,
      },
    });
  };

  const custom_config = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    removePlugins: ["Title"],
  };
  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader);
    };
  }

  class MyUploadAdapter {
    constructor(loader) {
      // CKEditor 5's FileLoader instance.
      this.loader = loader;
    }

    upload() {
      return this.loader.file.then((uploadedFile) => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append("image", uploadedFile);

          API.post("image-upload", data)
            .then((response) => {
              if (response.data.success) {
                resolve({
                  default: response.data.data.image_url,
                });
              } else {
                reject(response.data.message);
              }
            })
            .catch((response) => {
              reject("Upload failed");
            });
        });
      });
    }
  }

  return (
    <>
      <TemplateNavbar />
      {isMobile ? (
        <CkEditorMobile
          mobile_body={mobile_body}
          BalloonEditor={BalloonEditor}
          custom_config={custom_config}
          onChange={onChange}
        />
      ) : (
        <CkEditorDesktop
          custom={custom}
          BalloonEditor={BalloonEditor}
          custom_config={custom_config}
          onChange={onChange}
        />
      )}
      <TemplateFooter />
    </>
  );
}
