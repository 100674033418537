import React, { useContext } from "react";
import { API, AppStateContext, BanjosInput } from "../../../shared";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { useParams } from "react-router-dom";

export const SeoEditor = () => {
  const { slug: type } = useParams();
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const [value] = useDebounce(appState.slug, 1000);
  const { slug, meta_title, meta_description, gtm, initialSlug } = appState;

  const [state, setState] = useImmer({
    slugValidation: false,
    slugValidayionMessage: "",
    slugError: false,
  });

  useEffect(() => {
    if (!type) {
      setState((draft) => {
        draft.slugValidation = true;
      });
    }
  }, [type]);

  useEffect(() => {
    if (value && value !== initialSlug && state.slugValidation) {
      API.post("slug-validate", { slug: appState.slug })
        .then((res) => {
          if (!res.data.success) {
            setState((draft) => {
              draft.slugError = true;
              draft.slugValidayionMessage = res.data.message;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [value, state.slugValidation]);

  const onTextChange = (e) => {
    const { value, name } = e.target;
    if (name === "slug") {
      setState((draft) => {
        draft.slugValidayionMessage = "";
        draft.slugError = false;
        draft.slugValidation = true;
      });
    }
    appScopeDispatch({
      type: "ON_SEO_TEXT_CHANGE",
      payload: {
        name,
        value,
      },
    });
  };
  return (
    <>
      <BanjosInput
        type="text"
        label="URL Slug"
        description="https://www.banjos.com.au/"
        name="slug"
        onChange={onTextChange}
        value={slug}
        error={state.slugError}
        errorMessage={state.slugValidayionMessage}
      />
      <BanjosInput
        type="text"
        label="Meta Title*"
        name="meta_title"
        onChange={onTextChange}
        value={meta_title}
      />
      <BanjosInput
        type="textarea"
        label="Meta Description"
        name="meta_description"
        onChange={onTextChange}
        value={meta_description}
      />
      <BanjosInput
        type="textarea"
        label="Meta Data"
        name="gtm"
        onChange={onTextChange}
        value={gtm}
      />
    </>
  );
};
