import styled from "styled-components";
import parse from "html-react-parser";
import { FRONT_END_BASE_URL } from "../../../shared";

export function FavouriteCategoryBlock(data) {
  const { favCategories } = data.data;

  const Title = styled.div`
    color: ${(props) => props.theme};
  `;
  const Text = styled.div`
    color: ${(props) => props.theme};
  `;
  const Button = styled.button`
    ${(props) => props.theme};
  `;

  return (
    <div className="favourite-category-block-wrapper">
      <div className="favourite-category-block-container">
        <div className="favourite-category-block-head">Banjo's Favourites</div>
        <div className="favourite-category-block-items container container-max">
          {favCategories.map((data, index) => (
            <div className="favourite-category-block-item" key={index}>
              <div className="first-col">
                <Title className="category-block-title" theme={data.titleColor}>
                  {parse(data.title ?? "")}
                </Title>
                <Text className="category-block-des" theme={data.desColor}>
                  {parse(data.des1 ?? "")}
                  {parse(data.des2 ?? "")}
                </Text>
              </div>
              <div className="secound-col">
                <a href={`${FRONT_END_BASE_URL}/${data.link}`}>
                  <Button
                    className="category-block-btn"
                    theme={{
                      color: `${data.btnColor}`,
                      backgroundColor: `${data.btnBgColor}`,
                    }}
                  >
                    {parse(data.btnText ?? "")}
                  </Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
