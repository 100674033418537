import { useContext, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import { API } from "../api";
import { AppStateContext } from "../contexts";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { useEffect } from "react";

export const useBanjosNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const updateMode = location.pathname.split("/").includes("edit");
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const {
    storeTemplate,
    title,
    slug,
    meta_title,
    meta_description,
    selectedTemplate,
    meta_keyword,
    gtm,
    selectedStore,
    stores,
    id,
    status,
    custom,
    mobile_body,
  } = appState;

  const [state, setState] = useImmer({
    isBusy: false,
    isOpen: false,
    value: "desktop",
  });

  useEffect(() => {
    if (currentQueryParameters.get("mode") && !state.isLoaded) {
      setState((draft) => {
        draft.value = currentQueryParameters.get("mode");
      });
    }
  }, [currentQueryParameters]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setState((draft) => {
          draft.isOpen = false;
        });
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onPublish = (type) => {
    setState((draft) => {
      draft.isBusy = true;
    });
    // const { bannerImage1, bannerImage2, bannerImage3 } = storeTemplate;

    // let schemas = [];
    // bannerImage1.imageSchema && schemas.push(bannerImage1.imageSchema);
    // bannerImage2.imageSchema && schemas.push(bannerImage2.imageSchema);
    // bannerImage3.imageSchema && schemas.push(bannerImage3.imageSchema);

    const body =
      selectedTemplate === "storeTemplate"
        ? storeTemplate
        : "custom"
        ? custom
        : null;
    let payload = {
      title,
      slug,
      meta_title,
      meta_description,
      meta_keyword,
      gtm,
      selectedTemplate,
      store_id: selectedStore.id,
      status: type === "publish" ? 1 : 0,
      // imageSchemas: schemas,
      body,
    };
    if (selectedTemplate === "custom") {
      payload = {
        ...payload,
        mobile_body,
        mobile_body_enabled: mobile_body ? true : false,
      };
    }
    API.post("create", payload)
      .then((res) => {
        if (res.data.success) {
          setState((draft) => {
            draft.isBusy = false;
          });
          toast.success("Created successfully");
          navigate("/");
        } else {
          setState((draft) => {
            draft.isBusy = false;
          });
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(`An error has occured`);
        setState((draft) => {
          draft.isBusy = false;
        });
      });
  };

  const filteredStoreList = useMemo(() => {
    return stores
      ? stores
          .filter((store) => !store.storeTemplate)
          .map((c) => ({ value: c.id, label: c.name }))
      : [];
  }, [stores]);

  const unFilteredStoreList = useMemo(() => {
    return stores
      ? stores.map((c) => {
          return { value: c.id, label: c.name };
        })
      : [];
  }, [stores]);

  const storeList = updateMode ? unFilteredStoreList : filteredStoreList;

  const onChange = (e) => {
    const { value, name } = e.target;
    if (name === "selectedStore") {
      const selectedStore = stores.find((item) => item.id === +value);
      appScopeDispatch({
        type: "ON_SET_SELECTED_STORE",
        payload: {
          store: selectedStore,
        },
      });
    }
  };

  const onUpdate = (type) => {
    setState((draft) => {
      draft.isBusy = true;
    });

    // const { bannerImage1, bannerImage2, bannerImage3 } = storeTemplate;

    // let schemas = [];
    // bannerImage1.imageSchema && schemas.push(bannerImage1.imageSchema);
    // bannerImage2.imageSchema && schemas.push(bannerImage2.imageSchema);
    // bannerImage3.imageSchema && schemas.push(bannerImage3.imageSchema);

    const body =
      selectedTemplate === "storeTemplate"
        ? storeTemplate
        : "custom"
        ? custom
        : null;

    let payload = {
      title,
      slug,
      meta_title,
      meta_description,
      meta_keyword,
      gtm,
      selectedTemplate,
      store_id: selectedTemplate === "storeTemplate" && selectedStore.id,
      // imageSchemas: schemas,
      body,
      status: type === "publish" ? 1 : 0,
    };
    if (selectedTemplate === "custom") {
      payload = {
        ...payload,
        mobile_body,
        mobile_body_enabled: mobile_body ? true : false,
      };
    }
    API.put(`update/${id}`, payload)
      .then((res) => {
        if (res.data.success) {
          setState((draft) => {
            draft.isBusy = false;
          });
          toast.success("Updated successfully");
          navigate("/");
        } else {
          setState((draft) => {
            draft.isBusy = false;
          });
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(`An error has occured`);
        setState((draft) => {
          draft.isBusy = false;
        });
      });
  };

  const onDropdownClick = () => {
    setState((draft) => {
      draft.isOpen = !draft.isOpen;
    });
  };

  const handleDropDownChange = (value) => {
    setState((draft) => {
      draft.isOpen = !draft.isOpen;
    });
    navigate(`${location.pathname}?mode=${value}`, { replace: true });
  };

  return {
    onPublish,
    state,
    navigate,
    onChange,
    onUpdate,
    updateMode,
    location,
    selectedTemplate,
    onDropdownClick,
    handleDropDownChange,
    dropdownRef,
  };
};
