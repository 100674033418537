import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import { API, AppStateContext, initialData } from "../../../shared";

export const useHomePage = () => {
  const { appScopeDispatch, appState } = useContext(AppStateContext);
  const { currentTab } = appState;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    isBusy: false,
    data: [],
    filteredData: [],
    searchText: "",
    isOpen: false,
    id: null,
  });

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("admin-token", token);
      navigate("/");
    }
  }, [searchParams]);

  useEffect(() => {
    getPageTemplateList(currentTab);
  }, []);

  useEffect(() => {
    if (state.data) {
      const filteredData = state.data.filter((item) =>
        item.title.toLowerCase().includes(state.searchText.toLowerCase())
      );
      setState((draft) => {
        draft.filteredData = filteredData;
      });
    }
  }, [state.searchText, state.data]);

  const getPageTemplateList = (tab) => {
    let url = "list";
    if (tab === 1) {
      url = "list?template=storeTemplate";
    }
    setState((draft) => {
      draft.isBusy = true;
    });
    API.get(url)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setState((draft) => {
            draft.data = res.data.data;
            draft.isBusy = false;
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setState((draft) => {
          draft.isBusy = false;
        });
      });
  };

  const deleteTemplate = (id) => {
    API.delete(`delete?id=${id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success("Deleted successfully");
          setState((draft) => {
            draft.data = draft.data.filter((item) => item.id !== id);
            draft.isOpen = false;
          });
        } else {
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err) => {
        toast.error("An Error has Occured");
        console.log(err);
      });
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setState((draft) => {
      draft.searchText = value;
    });
  };
  const resetGlobalState = () => {
    appScopeDispatch({
      type: "RESET_STATE",
    });
  };
  const toggleModal = (id = null) => {
    setState((draft) => {
      draft.id = id;
      draft.isOpen = !draft.isOpen;
    });
  };

  const onStausChange = (e, id, index) => {
    const status = e.target.value === "0" ? "draft" : "publish";
    API.put(`status-update/${id}/${status}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setState((draft) => {
            draft.data[index].status = e.target.value === "0" ? 1 : 0;
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Error has Occured");
      });
  };

  const changeTab = (tab) => {
    tab !== 3 && getPageTemplateList(tab);
    appScopeDispatch({
      type: "CHANGE_TAB",
      payload: {
        tab,
      },
    });
    setState((draft) => {
      draft.searchText = "";
    });
  };

  return {
    state,
    deleteTemplate,
    getPageTemplateList,
    onSearch,
    resetGlobalState,
    toggleModal,
    onStausChange,
    changeTab,
    currentTab,
  };
};
