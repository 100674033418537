import { useContext, useRef } from "react";
import { useImmer } from "use-immer";
import {
  AppStateContext,
  onAddLogo,
  onDeleteLogo,
  onSelectLogoElement,
} from "../../../shared";

export function ProudlySupportBlock() {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { proudlySupp } = appState.storeTemplate;
  const { selectedElement, index } = appState;

  const IconRef = useRef(null);
  const onLogoClick = () => {
    IconRef.current.click();
  };
  return (
    <div className="proudly-support-block-wrapper">
      <div className="proudly-support-block-container container container-max">
        <div className="proudly-support-block-flexbox">
          <div className="proudly-support-block-title">Proudly Supporting</div>
          <div className="proudly-support-block-items">
            {proudlySupp.map((item, i) => {
              if (item.show) {
                return (
                  <div
                    onClick={() => {
                      onSelectLogoElement(
                        appScopeDispatch,
                        "proudlySupp",
                        "proudlySuppEdior",
                        i
                      );
                    }}
                    key={i}
                    className="proudly-support-block-item"
                  >
                    <img
                      alt="supporting1"
                      src={item.logo}
                      className={`proudly-support-logo ${
                        selectedElement === "proudlySupp" && index === i
                          ? "element-selected"
                          : ""
                      }`}
                    />
                    <button className="secondary-btn">
                      <img
                        onClick={() =>
                          onDeleteLogo(appScopeDispatch, "proudlySupp", i)
                        }
                        src="/icons/delete_icon.svg"
                      />
                    </button>
                  </div>
                );
              }
            })}

            {/* <div className="proudly-support-block-item">
              <img alt="supporting2" src="/images/supporting2.png" />
              <button className="secondary-btn">
                <img src="/icons/delete_icon.svg" />
              </button>
            </div>

            <div className="proudly-support-block-item">
              <img alt="supporting3" src="/images/supporting3.png" />
              <button className="secondary-btn">
                <img src="/icons/delete_icon.svg" />
              </button>
            </div> */}
            <div className="proudly-support-block-add-item">
              <button onClick={() => onLogoClick()} className="secondary-btn">
                <input
                  type="file"
                  onChange={(e) =>
                    onAddLogo(appScopeDispatch, "proudlySupp", e)
                  }
                  style={{ display: "none" }}
                  ref={IconRef}
                />
                <img src="/icons/add_icon.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
