import React, { useCallback } from "react";

export const BanjosInput = (props) => {
  const {
    type,
    label,
    description,
    fontDetails,
    placeholder,
    onChange,
    name,
    value,
    isHideElement = false,
    isHide = true,
    onClickHideElement,
    options,
    error = false,
    errorMessage,
    disabled,
    externalLink = false,
    onExternalChange,
    externalLinkValue,
    id,
  } = props;

  switch (type) {
    case "text":
      return (
        <div className="banjos-input-wrapper">
          {(label || isHideElement) && (
            <div className="banjos-input-label">
              {label || <span></span>}
              {isHideElement && (
                <div>
                  {isHide ? (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_open.svg"
                    />
                  ) : (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_close.svg"
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {description && <div className="banjos-input-des">{description}</div>}

          <input
            type="text"
            className="banjos-input-text"
            placeholder={placeholder || "Type Here"}
            onChange={onChange}
            name={name}
            value={value}
            disabled={disabled}
          />
          {error && <small className="text-danger pt-1">{errorMessage}</small>}
          {externalLink && (
            <div className="d-flex flex-row justify-content-between mt-2">
              <div>External Link</div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={externalLinkValue}
                  id="flexCheckDefault"
                  onChange={(e) => onExternalChange(e, id)}
                />
              </div>
            </div>
          )}
        </div>
      );
    case "color":
      return (
        <div className="banjos-input-wrapper">
          {(label || isHideElement) && (
            <div className="banjos-input-label">
              {label || <span></span>}
              {isHideElement && (
                <div>
                  {isHide ? (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_open.svg"
                    />
                  ) : (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_close.svg"
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {fontDetails && (
            <div className="banjos-input-font-details">
              <div className="font-name">{fontDetails.fontName}</div>
              <div className="font-weight-and-size">
                <span>{fontDetails.fontWeight} </span>
                <span>{fontDetails.fontSize} </span>
              </div>
            </div>
          )}

          <div className="banjos-input-color">
            <input type="color" onChange={onChange} name={name} value={value} />
          </div>
        </div>
      );
    case "textarea":
      return (
        <div className="banjos-input-wrapper">
          {(label || isHideElement) && (
            <div className="banjos-input-label">
              {label || <span></span>}
              {isHideElement && (
                <div>
                  {isHide ? (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_open.svg"
                    />
                  ) : (
                    <img
                      onClick={() => onClickHideElement()}
                      src="/icons/eye_close.svg"
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {description && <div className="banjos-input-des">{description}</div>}

          <textarea
            className="banjos-input-text textarea"
            placeholder="Type Here"
            onChange={onChange}
            name={name}
            value={value}
          ></textarea>
        </div>
      );
    case "select":
      return (
        <select
          value={value}
          onChange={onChange}
          name={name}
          className="banjos-select"
          disabled={disabled}
        >
          {options?.map((data, index) => (
            <option value={data.value}> {data.label}</option>
          ))}
        </select>
      );
    default:
      return <>type Error</>;
  }
  return <></>;
};
