import Loader from "react-loader";

export function BanjosLoader({ position }) {
  return (
    <div style={{ minHeight: "380px" }}>
      <Loader
        loaded={false}
        lines={12}
        length={20}
        width={10}
        radius={30}
        corners={1}
        rotate={0}
        direction={1}
        color="#000"
        speed={1}
        trail={40}
        shadow={false}
        hwaccel={false}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={0.6}
        loadedClassName="loadedContent"
        position={position}
      />
    </div>
  );
}
