import styled from "styled-components";
import { FRONT_END_BASE_URL } from "../../../shared";
import parse from "html-react-parser";

export function OrderOnlineBlock({ data }) {
  const { orderOnlineSection } = data;

  const Wrapper = styled.div`
    background-color: ${orderOnlineSection.backgroundColor};
  `;
  const Title = styled.div`
    color: ${orderOnlineSection.headerColor};
  `;
  const Text = styled.div`
    color: ${orderOnlineSection.descriptionColor};
  `;
  const Button = styled.button`
    color: ${orderOnlineSection.btnColor};
    background-color: ${orderOnlineSection.btnBgColor};
  `;
  return (
    <div className="order-online-block-wrapper">
      <Wrapper className="order-online-block-container">
        <Title className="order-online-block-title">
          {orderOnlineSection.headerText}
        </Title>
        <Text className="order-online-block-description">
          {parse(orderOnlineSection.description ?? "")}
        </Text>
        <a href={`${FRONT_END_BASE_URL}/?${orderOnlineSection.link}`}>
          <Button className="order-online-block-button">
            {parse(orderOnlineSection.btnText ?? "")}
          </Button>
        </a>
      </Wrapper>
    </div>
  );
}
