import React from "react";
import { Button, Row } from "reactstrap";
import { FooterEditorTool } from "./FooterEditorTool";
import { FooterTemplate } from "./FooterTemplate";
import { useFooterTemplate } from "../hooks";
import { BanjosLoader } from "../../../shared";

export const FooterSettings = () => {
  const {
    state,
    onDragEnd,
    onAddItem,
    onDeleteItem,
    onAddColumn,
    onDeleteColumn,
    onChangeItem,
    onContentChange,
    onHide,
    footer,
    onUpdateFooter,
    onExternalChange,
    getFooterDetails,
  } = useFooterTemplate();
  const { showCancelButton, saveButtonBusy } = footer;

  if (footer.isBusy) {
    return <BanjosLoader />;
  }

  return (
    <div className="homepage-wrapper">
      <div className="table-pagelist">
        <div className="save-btn">
          {showCancelButton && (
            <Button
              onClick={() => {
                getFooterDetails();
              }}
              className="me-3"
            >
              CANCEL
            </Button>
          )}
          <button
            onClick={() => {
              onUpdateFooter();
            }}
            className="primary-btn "
            disabled={saveButtonBusy}
          >
            SAVE
          </button>
        </div>
        <div className="template-editor-wrapper">
          <Row className="gx-0">
            <div className="template-editor-canvas">
              <FooterTemplate
                state={state}
                onDragEnd={onDragEnd}
                onContentChange={onContentChange}
                onChangeItem={onChangeItem}
              />
            </div>
            <div className="template-editor-tools edit">
              <FooterEditorTool
                state={state}
                onAddColumn={onAddColumn}
                onDeleteColumn={onDeleteColumn}
                onAddItem={onAddItem}
                onDeleteItem={onDeleteItem}
                onChangeItem={onChangeItem}
                onHide={onHide}
                onExternalChange={onExternalChange}
              />
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};
